import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ItemStyles from '../../../styles/item-styles';

const ItemCard = (props) => {
  const { title, slug, tag, date: articleDate, objective } = props.frontMatter;
  
  const useDate = () => {
    const event = new Date(articleDate);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return event.toLocaleDateString('ru-RU', options)
  }

  return (
    <ItemStyles>
      <Entry>
        <Link to={`/blog/${slug}`}>
          <h2>{title}</h2>
          <p>{objective}</p>
        </Link>
      </Entry>

      <Meta>
        <DateContainer>{useDate()}</DateContainer>
        <Tag>
          <Link to={`/`}>#{tag}</Link>
        </Tag>
      </Meta>
    </ItemStyles>
  );
};

const Entry = styled.div`
  h2 {
    font-size: 28px;
    margin: 0;
  }
  p {
    margin: 12px 0;
  }

  @media only screen and (max-width: 1200px) {
    h2 {
      font-size: 22px;
    }
    p {
      font-size: 16px;
    }
  }
`;


const Meta = styled.div`
  display: flex;
  align-items: flex-start;
  font-weight: bold;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    display: block;
    margin-top: 10px;
  }
`;

const DateContainer = styled.time`
  width: 100px;
  font-family: 'Inconsolata', monospace;
  font-weight: 400;
`;
const Tag = styled.div`
  a {
    display: inline-block;
    align-items: center;
    background: ${(props) => props.theme.red};
    color: #fff;
    padding: 2px 4px;
    font-size: 14px;
    span {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
`;

export default ItemCard;
