import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../../seo';
import Layout from '../../layout';
import Content_Sidebar_Wrap from '../../general/Content_Sidebar_Wrap';
import Pagination from './Pagination';
import ItemCard from './ItemCard';
import AdBlock from './AdBlock';
import Footer from '../../footer';
import PopularPosts from '../../popular';

class BlogTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const posts = data.allMdx.edges;

    return (
      <Layout>
        <SEO
          customTitle="Статьи и уроки для изучения Javascript"
          canonical={'/'}
        />
        <AdBlock />
        <Inner>         
          <Content_Sidebar_Wrap>
            {posts.map(({ node }, i) => {
              return <ItemCard key={i} frontMatter={node.frontmatter} />;
            })}
            <Pagination pageContext={pageContext} />
          </Content_Sidebar_Wrap>

          <PopularPosts />
        </Inner>

        <Footer />

      </Layout>
    );
  }
}

const Inner = styled.div`
  // padding: ${(props) => props.theme.innerPadding};
  display: grid;
  grid-template-columns: minmax(524px, 930px) minmax(120px, 320px);
  padding: 0px 70px 0px 40px;

  @media only screen and (max-width: 1200px) {
    padding: 0px 52px 0px 28px;
  }
  @media only screen and (max-width: 1000px) {
    display: block;
    padding: 0px 22px 0px 22px;
  }
`;

export default BlogTemplate;

export const pageQuery = graphql`
  query itemsPageQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            title
            objective
            tag
            slug
            date
          }
        }
      }
    }
  }
`;
