import React from 'react';
import styled from 'styled-components';
// import Sidebar from '../Sidebar';

class Content_Sidebar_Wrap extends React.Component {
  render() {
    return (
      <Container>
        <Main>{this.props.children}</Main>
        {/* <Sidebar /> */}
      </Container>
    );
  }
}

const Container = styled.div`
  margin-top: 42px;
  margin-right: 75px;

  @media only screen and (max-width: 1200px) {
    margin-right: 46px;
  }

  @media only screen and (max-width: 1000px) {
    margin-right: 0;
  }
`;

const Main = styled.main`
  max-width: 930px;

`;

export default Content_Sidebar_Wrap;
