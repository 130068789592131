import React from 'react';
import styled from 'styled-components';


const AdBlock = () => {
  return (
    <Container>
      <ul>
        <li>Вы приступили к изучению JavaScript, но по каким-то причинам застряли?</li>
        <li>Вы хотите систематизировать и структурировать ваше погружение в экосистему JS?</li>
        <li>На Stackdev доступен мой базовый курс по изучению <a href="https://stackdev.ru/courses/javascript" target="_blank">JavaScript для начинающих</a> + <a href="https://stackdev.ru/courses/js-practice" target="_blank">мини проекты</a> для портфолио и практики!</li>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 22px;
  padding: 0 34px;

  ul {
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    background: #fff;
    max-width: 1164px;
    padding: 13px;

    li {
      margin: 0;
      border-bottom: 1px solid #e0e0e0;
      padding: 4px;
      list-style: none;
    }

    li::before {
      content: "■";
      margin-right: 0.5rem;
      color: #fec600;
    }

    a {
      border-bottom: 2px solid #fec600;
    }
  }


`;


export default AdBlock;
