import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';


const PopularPosts = () => (
  <Container>
 
    <h3>Популярные статьи</h3>
    <ul>
      <li>
        <Link to="/blog/js-tasks">Задачи JavaScript для начинающих</Link>
      </li>
      <li>
        <Link to="/blog/js-types">Типы данных в JavaScript</Link>
      </li>
      <li>
        <Link to="/blog/empty-object">Как проверить объект JavaScript на пустоту?</Link>
      </li>
      <li>
        <Link to="/blog/event-listeners">Обработчики Событий в JS</Link>
      </li>
      <li>
        <Link to="/blog/destructuring">Деструктуризация в Javascript</Link>
      </li>
      <li>
        <Link to="/blog/top-js-methods">Массивы Javascript: перебирающие методы</Link>
      </li>
      <li>
        <Link to="/blog/spread-rest-operator">Операторы Spread и Rest в Javascript</Link>
      </li>
      <li>
        <Link to="/blog/datejs">Объект Date: Текущая Дата и Время в Javascript</Link>
      </li>
      <li>
        <Link to="/blog/js-variables">Переменные JavaScript var, let и const</Link>
      </li>
    </ul>
  </Container>
);

export default PopularPosts;

const Container = styled.div`
  margin-top: 62px;

  h3 {
    color: #444;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {

      a {
        display: block;
        font-size: 16px;
        color: #60656f;
        border-bottom: 1px solid #f1f5fc;
        padding: 11px 0;
        line-height: 20px;

      }
    }
  }

  @media only screen and (max-width: 1200px) {
    ul li a {
      font-size: 14px;
    }

    h3 { 
      font-size: 22px;
      margin-bottom: 18px;
    }
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }

`;
