import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Footer = () => (
  <Container>
    <Wrap>
      <CopyRight>
        © {new Date().getFullYear()}
        {` `}
        <Link to="/">StackDev.Blog</Link>
      </CopyRight>
    </Wrap>
  </Container>
);

const Container = styled.footer`
  font-family: 'Inconsolata', monospace;
  font-weight: 400;
  margin: 0px 70px 0px 40px;
  padding: 5rem 0;
`;
const Wrap = styled.div`
  text-align: center;
  a {
    color: white;
  }
  p {
    margin: 0;
  }
`;
const CopyRight = styled.div`
  margin: 0 auto;
  font-size: 16px;
  color: rgb(148, 161, 175);
  a {
    color: rgb(148, 161, 175);
  }
  @media only screen and (max-width: 390px) {
    margin-top: 5px;
  }
`;


export default Footer;
